import { useFetcher } from "@remix-run/react";
import { Calendar, Car, Gauge, ArrowRight, Heart, Star, MapPin } from "lucide-react";
import { useEffect, useState } from "react";
import { Badge } from "#app/components/ui/badge.tsx";
import { Skeleton } from "#app/components/ui/skeleton.tsx";
import { formatText } from "#app/utils/format-text.tsx";
import { getCarImgSrc } from "#app/utils/misc.tsx";

export function NormalCarListing({
  data,
  user,
  showFavorite = true,
}: {
  data: any;
  user?: any;
  showFavorite?: boolean;
}) {
  const [imageStatus, setImageStatus] = useState<'loading' | 'loaded' | 'error'>('loading');
  const favoriteFetcher = useFetcher();

  const isFavorite = user?.favorites?.some(
    (fav: { carId: number }) => fav.carId === data.id,
  );

  const price = data.price.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatState = (state: string) => {
    const match = state.match(/\(([^)]{2})\)/);
    return match ? match[1].toUpperCase() : "";
  };

  const numberToLocale = (number: number) => {
    return new Intl.NumberFormat("pt-BR").format(number);
  };

  const location = `${formatText(data.city)} / ${formatState(data.state)}`;
  const year = `${data.fabricationYear}/${data.modelYear}`;
  const odometer = `${numberToLocale(data.odometer)} km`;
  const carName = `${formatText(data.maker)} ${formatText(data.model)}`;

  // Handle images that might already be cached
  useEffect(() => {
    if (!data.images?.[0]?.id) {
      setImageStatus('error');
      return;
    }
    
    const img = new Image();
    img.src = `https://carregados.com.br/cdn-cgi/image/format=auto,sharpen=1,saturation=1.4,width=960,height=480,fit=contain${getCarImgSrc(
      data.images[0].id,
    )}`;
    
    if (img.complete) {
      setImageStatus('loaded');
    } else {
      img.onload = () => setImageStatus('loaded');
      img.onerror = () => setImageStatus('error');
    }
    
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [data.images]);

  return (
      <div className="bg-white dark:bg-gray-900 rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl hover:translate-y-[-5px] group flex flex-row sm:flex-col">
        <div className="relative overflow-hidden w-[45%] sm:w-full h-full sm:h-auto aspect-[4/3]">
          {showFavorite && (
            <favoriteFetcher.Form
              method="post"
              action="/resources/favorite"
              className="absolute left-2 sm:right-2 sm:left-auto top-2 z-10 rounded-full bg-white/80 p-1 backdrop-blur-sm transition-all hover:bg-white"
            >
              <input type="hidden" name="carId" value={data.id} />
              <input type="hidden" name="carUrl" value={data.url} />
              <input type="hidden" name="userId" value={user?.id} />
              <input
                type="hidden"
                name="intent"
                value={isFavorite ? "remove" : "insert"}
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                }}
                type="submit"
                className="rounded-full flex items-center justify-center bg-transparent active:scale-75 transition-transform duration-150"
              >
                <Heart
                  className={`h-4 w-4 sm:h-5 sm:w-5 ${
                    isFavorite ? "fill-red-500 text-red-500" : "text-gray-500"
                  }`}
                />
              </button>
            </favoriteFetcher.Form>
          )}
          
          <div className="absolute inset-0 w-full h-full min-h-[140px]">
            {/* Skeleton placeholder while image loads */}
            {imageStatus === 'loading' && (
              <Skeleton className="absolute inset-0 bg-gray-100 dark:bg-gray-800 flex items-center justify-center">
                <Car className="text-gray-300 dark:text-gray-600" size={32} />
              </Skeleton>
            )}
            
            {/* Fallback for error */}
            {imageStatus === 'error' && (
              <div className="absolute inset-0 bg-gray-100 dark:bg-gray-800 flex items-center justify-center">
                <Car className="text-gray-400 dark:text-gray-500" size={40} />
              </div>
            )}
            
            {/* Actual image */}
            {data.images?.[0]?.id && (
              <img
                loading="lazy"
                alt={`Foto de um ${data.maker} ${data.model} ${data.version}.`}
                src={`https://carregados.com.br/cdn-cgi/image/format=auto,sharpen=1,saturation=1.4,width=960,height=480,fit=contain${getCarImgSrc(
                  data.images[0].id,
                )}`}
                className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${
                  data.isInactive ? "grayscale" : ""
                } ${imageStatus === 'loaded' ? 'opacity-100' : 'opacity-0'}`}
              />
            )}
          </div>
          
          {data.isInactive ? (
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-40">
              <span className="text-xl sm:text-4xl font-bold tracking-widest text-red-600">
                {showFavorite ? "VENDIDO" : "INATIVO"}
              </span>
            </div>
          ) : null}
        </div>
        
        <div className="py-1 px-1 sm:p-5 w-[55%] sm:w-full flex flex-col">
          <div className="flex justify-between items-start mb-1.5 sm:mb-4">
            <h3 className="text-sm sm:text-lg font-bold capitalize truncate">{carName}</h3>
            <p className="text-primary font-bold text-sm sm:text-base whitespace-nowrap ml-2">{price}</p>
          </div>
          
          <div className="space-y-1 sm:space-y-2 mb-1.5 sm:mb-4">
            <div className="p-1.5 sm:p-3 bg-gray-50 dark:bg-gray-800 rounded-lg flex justify-between items-center">
              <p className="sm:text-sm font-medium text-gray-500 dark:text-gray-400 hidden sm:block">KM</p>
              <Gauge className="h-3.5 w-3.5 text-gray-500 dark:text-gray-400 sm:hidden" />
              <p className="font-medium text-xs sm:text-sm ml-2">{odometer}</p>
            </div>
            <div className="p-1.5 sm:p-3 bg-gray-50 dark:bg-gray-800 rounded-lg flex justify-between items-center">
              <p className="sm:text-sm font-medium text-gray-500 dark:text-gray-400 hidden sm:block">Ano</p>
              <Calendar className="h-3.5 w-3.5 text-gray-500 dark:text-gray-400 sm:hidden" />
              <p className="font-medium text-xs sm:text-sm ml-2">{year}</p>
            </div>
            <div className="p-1.5 sm:p-3 bg-gray-50 dark:bg-gray-800 rounded-lg flex justify-between items-center">
              <p className="sm:text-sm font-medium text-gray-500 dark:text-gray-400 hidden sm:block">Local</p>
              <MapPin className="h-3.5 w-3.5 text-gray-500 dark:text-gray-400 sm:hidden" />
              <p className="font-medium text-xs sm:text-sm ml-2 truncate">{location}</p>
            </div>
          </div>
          
          <div className="mt-auto flex justify-end items-center">
            <div className="inline-flex justify-end items-center text-primary font-medium text-xs sm:text-sm sm:flex hidden ml-auto">
              Ver detalhes
              <ArrowRight className="ml-1 h-3 w-3 sm:h-4 sm:w-4" />
            </div>
          </div>
        </div>
      </div>
  );
}
